import type { ExtractPropTypes } from "vue";

export const baseInputProps = {
  hint: {
    type: String,
    default: "",
  },
  error: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  prependInnerIcon: {
    type: Object,
    default: null,
  },
  appendInnerIcon: {
    type: Object,
    default: null,
  },
  color: {
    type: String,
    default: "primary",
  },
  disabled: Boolean,
  readonly: Boolean,
  loading: Boolean,
  modelValue: String,
};

export type BaseInputProps = ExtractPropTypes<typeof baseInputProps>;
