<script lang="ts" setup>
import { baseInputProps } from "~/components/ui/Input/props";

const props = defineProps(baseInputProps);

defineEmits<{
  (e: "click:inputWrapper"): void;
  (e: "click:innerIcon"): void;
}>();

const attrs = useAttrs();

const elementIdToken = attrs.id || props.label;
const elementId = elementIdToken
  ? `v-input-${elementIdToken}-${Math.random().toString(36).slice(2, 7)}`
  : undefined;

const refRoot = ref();
const refInputContainer = ref();

defineExpose({
  refRoot,
  refInputContainer,
});
</script>

<template>
  <div
    ref="refRoot"
    class="v-input__base"
    :class="[
      props.disabled && 'is-disabled',
      (props.disabled || props.readonly) && 'is-readonly',
      props.error && 'is-error',
    ]"
  >
    <slot name="label">
      <label
        v-if="props.label"
        :for="elementId"
        class="v-input__base-label"
        :class="[props.error && 'text-danger']"
      >
        {{ props.label }}
      </label>
    </slot>
    <div ref="refInputContainer" class="v_input__base_container">
      <div class="v-input__base_wrapper" @click="$emit('click:inputWrapper')">
        <slot name="prepend-inner">
          <component
            :is="props.prependInnerIcon"
            v-if="props.prependInnerIcon"
            class="v-input__base-prepend-inner-icon v-input__base-inner-icon"
          />
        </slot>
        <slot
          :id="elementId"
          :readonly="props.readonly"
          :disabled="props.disabled"
          class="v-input__base-child"
        />
        <slot name="append-inner">
          <div v-if="props.loading" />
          <component
            :is="props.appendInnerIcon"
            v-else-if="props.appendInnerIcon"
            class="v-input__base-append-inner-icon v-input__base-inner-icon"
            :class="['v-input__base-inner-icon--hoverable']"
            @click="$emit('click:innerIcon')"
          />
        </slot>
      </div>
    </div>
    <slot name="bottom">
      <transition name="expand">
        <div v-show="props.error || props.hint" class="v-input__base-hint">
          <SvgoIconTooltip />
          <small
            class="inline-block"
            :class="[props.error ? 'v-input__base-hint__error' : '']"
          >
            {{ props.error || props.hint }}
          </small>
        </div>
      </transition>
    </slot>
  </div>
</template>
