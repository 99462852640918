<script lang="ts" setup>
import { defu } from "defu";
import type { ExtractPropTypes } from "vue";
import { baseInputProps } from "~/components/ui/Input/props";

const props = defineProps(
  defu(
    {
      modelValue: [String, Number],
    },
    baseInputProps,
  ),
);

defineEmits<{
  (
    e: "update:modelValue",
    value: ExtractPropTypes<typeof props>["modelValue"],
  ): void;
  (e: "click:innerIcon"): void;
}>();

const _baseInputProps = reactivePick(
  props,
  Object.keys(baseInputProps) as Array<keyof typeof baseInputProps>,
);

const attrs = useAttrs();
const input = ref<HTMLInputElement>();
const isInputTypeFile = attrs.type && attrs.type === "file";
const handleInputWrapperClick = () => {
  input.value?.focus();
};
</script>

<template>
  <UiInputVBaseInput
    v-bind="{ ..._baseInputProps, class: $attrs.class }"
    :class="[
      isInputTypeFile && 'v-input__type-file',
      `v-input--${props.color}`,
      `v-input--${props.size}`,
    ]"
    class="v-input"
    @click:input-wrapper="handleInputWrapperClick"
    @click:inner-icon="$emit('click:innerIcon')"
  >
    <template
      v-for="name in Object.keys($slots).filter(
        (slotName) => slotName !== 'default',
      )"
      #[name]="slotProps"
    >
      <slot :name="name" v-bind="slotProps || {}" />
    </template>
    <template #default="slotProps">
      <input
        v-bind="{ ...$attrs, ...slotProps }"
        ref="input"
        class="v-input-input"
        :value="props.modelValue"
        @input="
          (e) => {
            $emit('update:modelValue', (e.target as HTMLInputElement).value);
          }
        "
      />
    </template>
  </UiInputVBaseInput>
</template>
